import { scrollLock } from "@magnit/core/src/helpers/scrollLock";

export const MODALS = {
  Install: "install",
  InstallSuccess: "install-success",
  PushFullscreen: "push-fullscreen",
  PushInstruction: "push-instruction",
  SubscriptionCard: "subscription-card",
  SubscriptionRenew: "subscription-renew",
  Unsubscribe: "unsubscribe",
  UnsubscribeConfirm: "unsubscribe-confirm",
  UnsubscribeError: "unsubscribe-error",
  Calendar: "calendar",
  CardMerge: "card-merge",
  ProfileDelete: "profile-delete",
  FavoriteCategories: "favorite-categories",
} as const;

type Keys = keyof typeof MODALS;
export type IModalsName = (typeof MODALS)[Keys] | string;
export interface IModalOptions {
  clearable?: boolean;
  manual?: boolean;
  params?: unknown;
}

export const useAppModalsStore = defineStore("appModals", () => {
  const route = useRoute();
  const openedModals = ref<string[]>([]);
  const modalPromiseMap: Map<IModalsName, () => void> = new Map();

  const getActive = (): string | null =>
    openedModals.value.length > 0 ? openedModals.value[0] : null;

  const isOpen = (modal: IModalsName): boolean => openedModals.value.includes(modal);

  const open = (name: IModalsName, clearable?: boolean, manual?: boolean) => {
    if (clearable) {
      openedModals.value = [name];
    } else {
      openedModals.value.unshift(name);
    }

    if (!manual) {
      scrollLock.enable();
    }
    return new Promise<void>((resolve) => {
      modalPromiseMap.set(name, resolve);
    });
  };

  const close = (name: IModalsName) => {
    const resolve = modalPromiseMap.get(name);
    if (resolve) {
      modalPromiseMap.delete(name);
      resolve();
    }

    openedModals.value = openedModals.value.filter((n) => n !== name);

    if (!openedModals.value.length) {
      scrollLock.disable();
    }
  };

  const closeAll = () => {
    openedModals.value.forEach(close);
    scrollLock.disable();
  };

  watch(() => route.fullPath, closeAll);

  return {
    getActive,
    open,
    close,
    closeAll,
    isOpen,
    openedModals,
  };
});
